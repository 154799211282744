import styled from 'styled-components'
import { device } from '../../../../theme'

export const SectionWrap = styled.div`
  padding-top: 120px;
  padding-bottom: 120px;
  background-color: ${(props) => props.color};

  @media ${device.medium} {
    padding-top: 80px;
    padding-bottom: 80px;
  }
  @media ${device.small} {
    padding-top: 60px;
    padding-bottom: 60px;
  }

  &,
  h3,
  h4,
  h5,
  mark,
  span,
  p {
    font-family: ${(props) => props.theme.fontFamily.heading};
    color: ${(props) => props.theme.colors.white};
  }

  h2,
  h6 {
    font-family: ${(props) => props.theme.fontFamily.heading};
    color: ${(props) => props.theme.colors.white};
    font-weight: bold;
  }

  @media ${device.medium} {
    h2 {
      justify-content: center;
    }
  }
`

export const HeaderWrap = styled.div`
  margin-right: 3rem;

  @media ${device.medium} {
    margin-bottom: 70px;
    margin-right: 0;
    text-align: center;
  }
`
