import React, { useState } from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import SEO from '../components/seo'
import Header from '../containers/header'
import Footer from '../containers/footer'
import Hero from '../components/hero'
import BoxImage from '../containers/elements/box-image/section-home'
import BoxImageLarge from '../containers/elements/box-large-image/section-home'
import Events from '../containers/events'
import Counters from '../containers/elements/counters/section-home'
import Section from '../containers/elements/client-logo/section-home'

const IndexPage = ({ location }) => {
  const pageData = useStaticQuery(graphql`
    query HomepageQuery {
      site {
        siteMetadata {
          defaultTitle: title
          titleTemplate
          defaultDescription: description
          siteUrl
          defaultImage: image
        }
      }

      homepage: allContentfulPagina(
        filter: { id: { eq: "237b2cb8-4d1e-563d-a842-ed9e03eaea5f" } }
      ) {
        edges {
          node {
            headerTitel
            headerSubtitel
            headerButtonTekst
            headerButtonLink
            headerImage {
              file {
                url
                fileName
                contentType
              }
              fluid(maxWidth: 900) {
                ...GatsbyContentfulFluid_withWebp
              }
              title
              description
            }
          }
        }
      }

      articles: allContentfulPublicatie(
        limit: 4
        sort: { fields: date, order: DESC }
        filter: {
          title: { ne: null }
          slug: { regex: "/^(?!untitled-entry).+/" }
          date: { ne: null }
        }
      ) {
        edges {
          node {
            id
            title
            date(locale: "nl_NL", formatString: "DD MMMM YYYY")
            slug
            image {
              fluid(maxWidth: 260, cropFocus: CENTER) {
                ...GatsbyContentfulFluid_withWebp
              }
              description
            }
          }
        }
      }
    }
  `)

  const metadata = pageData.site.siteMetadata
  const latestArticles = pageData.articles.edges.map((edge) => edge.node)
  const {
    headerTitel,
    headerSubtitel,
    headerButtonTekst,
    headerButtonLink,
    headerImage,
  } = pageData.homepage.edges[0].node
  const [flyoutOpen, setFlyoutOpen] = useState(false)
  const regex = /(<([^>]+)>)/gi

  const flyoutHandler = () => {
    setFlyoutOpen((prevState) => !prevState)
  }

  return (
    <>
      <SEO
        title={`${headerTitel.replace(regex, '')}`}
        description={headerSubtitel || headerTitel}
        image={headerImage?.file?.url}
        metadata={metadata}
      />

      <Header
        transparent
        flyoutOpen={flyoutOpen}
        flyoutHandler={flyoutHandler}
      />
      <main id="main" className="site-wrapper-reveal">
        <Hero
          className="highlight"
          flyoutHandler={flyoutHandler}
          title={headerTitel}
          description={headerSubtitel}
          buttontext={headerButtonTekst}
          buttonlink={headerButtonLink}
          image={headerImage}
          search={true}
        />
        <BoxImage />
        <BoxImageLarge split={true} />
        <BoxImageLarge
          path="/actueel/publicaties"
          title="Recente publicaties"
          buttontext="publicaties"
          detailButtonText="Bekijk publicatie"
          items={latestArticles}
          padding={false}
        />
        <Events
          title="Agenda"
          path="/actueel/agenda"
          buttonText="agenda items"
        />
        <Counters />
        <Section />
      </main>
      <Footer />
    </>
  )
}

export default IndexPage
