import React from 'react'
import PropTypes from 'prop-types'
import { useStaticQuery, graphql } from 'gatsby'
import { Container, Row, Col } from '../../../../components/ui/wrapper'
import BoxImage from '../../../../components/box-image/layout-two'
import Heading from '../../../../components/ui/heading'
import Button from '../../../../components/ui/button'
import Text from '../../../../components/ui/text'
import {
  SectionWrap,
  SectionTitle,
  ButtonWrap,
  RowCenter,
} from './section.style'

const BoxSection = ({ headingStyle, linkStyle }) => {
  const ThemeData = useStaticQuery(graphql`
    query ThemeQuery {
      themes: allContentfulThema(limit: 4, sort: { fields: title }) {
        edges {
          node {
            id
            title
            url
            color
            image {
              file {
                url
                fileName
                contentType
              }
              title
              description
            }
          }
        }
      }
    }
  `)
  const themeList = ThemeData.themes.edges.map((edge) => edge.node)

  return (
    <SectionWrap className="container-xl">
      <Container>
        <RowCenter>
          <Col lg={7} xl={6}>
            <Row>
              {themeList.map((theme) => {
                return (
                  <Col
                    sm={6}
                    className="box-item"
                    key={`box-image-${theme.id}`}
                  >
                    <BoxImage
                      color={theme.color}
                      imageSrc={
                        theme.image && theme.image.file
                          ? theme.image.file.url
                          : ''
                      }
                      imageAltText={
                        theme.image.description !== ''
                          ? theme.image.description
                          : `Regio West-Brabant<br/> <strong>${theme.title}</strong>`
                      }
                      title={`Regio West-Brabant<br/> <strong>${theme.title}</strong>`}
                      path={`/themas/${theme.url}`}
                    />
                  </Col>
                )
              })}
            </Row>
          </Col>
          <Col lg={5}>
            <SectionTitle>
              <Heading mb="14px">Thema’s</Heading>

              <Text>
                RWB werkt integraal vanuit de thema's economie, arbeidsmarkt,
                mobiliteit en ruimte. Kennis en praktische achtergrondinformatie
                zijn gebundeld per opgave. Klik op een thema voor de laatste
                artikelen, publicaties en projecten.
              </Text>

              <ButtonWrap>
                <Button
                  to="/themas"
                  title="Ga naar onze thema overzichtpagina"
                  aria-label="Ga naar onze thema overzichtpagina"
                >
                  Bekijk alle thema's
                </Button>
              </ButtonWrap>
            </SectionTitle>
          </Col>
        </RowCenter>
      </Container>
    </SectionWrap>
  )
}

BoxSection.propTypes = {
  headingStyle: PropTypes.object,
  linkStyle: PropTypes.object,
}

BoxSection.defaultProps = {
  headingStyle: {
    as: 'h3',
    fontSize: '18px',
    fontweight: 500,
    lineHeight: 1.4,
    color: '#333333',
    mt: '40px',
    textalign: 'center',
  },
  linkStyle: {
    layout: 'underline',
    hover: {
      layout: 2,
    },
  },
}

export default BoxSection
