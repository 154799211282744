import React from 'react'
import parse from 'html-react-parser'
import PropTypes from 'prop-types'
import Img from 'gatsby-image'
import Image from '../../image'
import {
  BoxImgWrapper,
  BoxImgInner,
  BoxImgMedia,
  BoxImgContent,
  HeadingWrap,
  BoxImgLink,
} from './box-image.style'

const BoxImage = ({
  color,
  imageSrc,
  imageAltText,
  title,
  headingStyle,
  path,
  ...boxStyles
}) => {
  let boxImage
  const regex = /(<([^>]+)>)/gi

  if (imageSrc.fixed && typeof imageSrc.fixed !== 'function') {
    boxImage = (
      <Img
        fixed={imageSrc.fixed}
        alt={
          imageAltText
            ? imageAltText.replace(regex, '')
            : title.replace(regex, '')
        }
      />
    )
  } else if (imageSrc.fluid) {
    boxImage = (
      <Image
        fluid={imageSrc.fluid}
        alt={
          imageAltText
            ? imageAltText.replace(regex, '')
            : title.replace(regex, '')
        }
      />
    )
  } else {
    boxImage = (
      <img
        src={imageSrc}
        alt={
          imageAltText
            ? imageAltText.replace(regex, '')
            : title.replace(regex, '')
        }
      />
    )
  }
  return (
    <BoxImgWrapper {...boxStyles}>
      <BoxImgInner>
        {imageSrc && <BoxImgMedia>{boxImage}</BoxImgMedia>}

        <BoxImgContent color={color}>
          {title && (
            <HeadingWrap as="div" {...headingStyle}>
              <BoxImgLink
                to={path}
                title={`Ga naar de ${title.replace(regex, '')} pagina`}
                aria-label={`Ga naar de ${title.replace(regex, '')} pagina`}
              >
                {parse(title)}
              </BoxImgLink>
            </HeadingWrap>
          )}
        </BoxImgContent>
      </BoxImgInner>
    </BoxImgWrapper>
  )
}

BoxImage.propTypes = {
  boxCSS: PropTypes.object,
  imageAltText: PropTypes.string,
  imageSrc: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  title: PropTypes.string,
  path: PropTypes.string,
  color: PropTypes.string,
}

BoxImage.defaultProps = {
  path: '/',
}

export default BoxImage
