import styled from 'styled-components'
import { Row } from '../../../../components/ui/wrapper'
import { device } from '../../../../theme'

export const SectionWrap = styled.div`
  padding-top: 100px;
  padding-bottom: 100px;
  background: ${(props) => props.theme.colors.background};

  @media ${device.medium} {
    padding-top: 80px;
    padding-bottom: 80px;
  }

  @media ${device.small} {
    padding-top: 60px;
    padding-bottom: 60px;
  }

  .box-item {
    margin-top: 30px;

    @media ${device.small} {
      margin-top: 16px;
    }
  }
`

export const SectionTitle = styled.div`
  @media ${device.medium} {
    margin-top: 30px;
    margin-bottom: 30px;
  }
`

export const ButtonWrap = styled.div`
  margin-top: 30px;
`

export const RowCenter = styled(Row)`
  align-items: baseline;
  justify-content: space-between;

  @media ${device.medium} {
    > div:first-child {
      order: 2;
    }

    > div:last-child {
      order: 1;
    }
  }
`
