import styled from 'styled-components'
import Heading from '../../ui/heading'
import { device } from '../../../theme'
import { Link } from 'gatsby'

export const HeadingWrap = styled(Heading)`
  font-family: ${(props) => props.theme.fontFamily.heading};
  font-size: 14px;
  line-height: 24px;
  font-weight: normal;
  margin-bottom: 0;

  strong {
    font-size: 16px;
    line-height: 20px;
    font-weight: 500;
    transition: ${(props) => props.theme.transition};
  }
`

export const BoxImgInner = styled.div`
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);
  background: #fff;
  padding: 18px 16px;
  min-height: 150px;
  display: flex;
  align-items: center;
  transition: ${(props) => props.theme.transition};

  &:hover {
    transform: translateY(-5px);
  }

  @media ${device.small} {
    padding: 16px;
  }
`

export const BoxImgMedia = styled.div`
  flex: 1 0;
  margin-right: 16px;
  max-width: 100px;

  img {
    min-width: 100px;
  }
`

export const BoxImgContent = styled.div`
  > * {
    color: ${(props) => props.theme.colors.textDark};
  }
`

export const BoxImgLink = styled(Link)`
  transition: ${(props) => props.theme.transition};
  transform: none !important;

  &:hover {
    color: ${(props) => props.theme.colors.theme};
  }

  &::before {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
  }
`

export const BoxImgWrapper = styled.div`
  position: relative;
  margin-top: ${(props) => props.mt};
  margin-bottom: ${(props) => props.mb};
`
