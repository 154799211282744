import React from 'react'
import PropTypes from 'prop-types'
import { graphql, useStaticQuery } from 'gatsby'
import { Container, Row, Col } from 'react-bootstrap'
import { SectionWrap, HeaderWrap } from './section.style'
import Heading from '../../../../components/ui/heading'
import Counter from '../../../../components/counter/layout-three'
import { theme } from '../../../../theme'

const Section = ({ markHeading, headingStyle, color }) => {
  const CounterData = useStaticQuery(graphql`
    query CounterQuery {
      townships: allContentfulGemeente(filter: { count: { eq: true } }) {
        totalCount
      }

      details: allContentfulBedrijf(
        filter: { id: { eq: "ca7bb2ca-5d8a-531e-a88e-80dc8c9e272b" } }
      ) {
        edges {
          node {
            agenda
            themes
            people
            jobs
          }
        }
      }
    }
  `)

  const townships = CounterData.townships.totalCount
  const { themes, agenda, jobs, people } = CounterData.details.edges[0].node

  return (
    <SectionWrap color={color} className="container-xl">
      <Row>
        <Container>
          <Row>
            <Col md={4} lg={{ offset: 1 }}>
              <HeaderWrap>
                <Heading {...markHeading}>
                  <mark>{townships}</mark> Gemeenten in regio West-Brabant
                </Heading>
                <Heading {...headingStyle}>
                  #<span>KrachtigeRegio</span>
                </Heading>
              </HeaderWrap>
            </Col>
            <Col lg={7} md={8}>
              <Row>
                <Col sm={6}>
                  <Counter countTo={people} text="Inwoners" />
                </Col>
                <Col sm={6}>
                  <Counter countTo={jobs} text="Banen" />
                </Col>
                <Col sm={6}>
                  <Counter countTo={themes} text="Thema's" />
                </Col>
                <Col sm={6}>
                  <Counter countTo={agenda} text="Agenda" />
                </Col>
              </Row>
            </Col>
          </Row>
        </Container>
      </Row>
    </SectionWrap>
  )
}

Section.propTypes = {
  counterStyle: PropTypes.object,
  color: PropTypes.string,
}

Section.defaultProps = {
  color: theme.colors.theme,
  markHeading: {
    layout: 'highlight',
  },
  headingStyle: {
    as: 'h3',
    mt: '25px',
    fontSize: '38px',
    child: {
      color: 'primary',
    },
    responsive: {
      large: {
        fontSize: '30px',
      },
      small: {
        fontSize: '24px',
      },
    },
  },
}

export default Section
